import React from 'react';
import Helmet from 'react-helmet';
import wrapInSection from '../components/wrapInSection';
import wrapInLayout from '../components/wrapInLayout';

const PrivacyPolicy = () => (
  <div className="privacy-wrapper page-wrapper narrow-container">
    <Helmet title="КЭСПА – Соглашение на обработку персональных данных" />
    <h2>Соглашение на обработку персональных данных</h2>

    <p>
      Присоединяясь к настоящему Соглашению и оставляя свои данные на Сайте{' '}
      <a href="https://kespa.ru/">kespa.ru</a> (далее&nbsp;–&nbsp;Сайт), путем
      заполнения полей онлайн-заявки (регистрации) Пользователь:
    </p>
    <ul className="marked">
      <li>
        подтверждает, что указанные им персональные данные принадлежат ему
        лично;
      </li>
      <li>
        дает согласие на обработку Сайтом предоставляемых персональных данных в
        целях регистрации Пользователя на Сайте;
      </li>
      <li>
        признает и подтверждает, что он внимательно и в полном объеме
        ознакомился с настоящим Соглашением и содержащимися в нем условиями
        обработки его персональных данных, указываемых им в полях онлайн заявки
        (регистрации) на сайте;
      </li>
      <li>
        признает и подтверждает, что все положения настоящего Соглашения и
        условия обработки его персональных данных ему понятны;
      </li>
      <li>
        выражает согласие с условиями обработки персональных данных без
        каких-либо оговорок и ограничений.
      </li>
    </ul>

    <p>
      Пользователь дает свое согласие на обработку его персональных данных, а
      именно совершение действий, предусмотренных Федеральный закон от 27 июля
      2006 г. N 152-ФЗ &quot;О&nbsp;персональных данных&quot; (с изменениями и
      дополнениями), и подтверждает, что, давая такое согласие, он действует
      свободно, по своей волей и в своих интересах. Настоящее согласие
      Пользователя применяется в отношении обработки следующих персональных
      данных:
    </p>
    <ul className="marked">
      <li>фамилия, имя, отчество;</li>
      <li>место пребывания (город, область);</li>
      <li>номера телефонов;</li>
      <li>адреса электронной почты (E-mail),</li>
      <li>названия учетных записей в Skype.</li>
    </ul>

    <p>
      Пользователь, предоставляет сервису{' '}
      <a href="https://kespa.ru/">kespa.ru</a> право осуществлять следующие
      действия (операции) с персональными данными:
    </p>
    <ul className="marked">
      <li>сбор и накопление;</li>
      <li>
        хранение в течение установленных нормативными документами сроков
        хранения отчетности, но не менее трех лет, с момента даты прекращения
        пользования услугами Сайта Пользователем;
      </li>
      <li>использование в целях регистрации Пользователя на Сайте;</li>
      <li>уточнение (обновление, изменение);</li>
      <li>уничтожение;</li>
      <li>
        передача по требованию суда, в т.ч. третьим лицам, с соблюдением мер,
        обеспечивающих защиту персональных данных от несанкционированного
        доступа.
      </li>
    </ul>

    <p>
      Указанное согласие действует бессрочно с момента предоставления данных и
      может быть отозвано Вами путем подачи заявления администрации Сайта с
      указанием данных, определенных ст. 14 Закона «О персональных данных».
      Отзыв согласия на обработку персональных данных может быть осуществлен
      путем направления Пользователем соответствующего распоряжения в простой
      письменной форме на адрес электронной почты (E-mail){' '}
      <a href="mailto:mail@kespa.ru">mail@kespa.ru</a>.
    </p>

    <p>
      Сайт не несет ответственности за использование (как правомерное, так и
      неправомерное) третьими лицами информации, размещенной Пользователем на
      Сайте, включая её воспроизведение и распространение, осуществленные всеми
      возможными способами.
    </p>

    <p>
      Сайт имеет право вносить изменения в настоящее Соглашение. При внесении
      изменений в актуальной редакции указывается дата последнего обновления.
      Новая редакция Соглашения вступает в силу с момента ее размещения, если
      иное не предусмотрено новой редакцией Соглашения.
    </p>

    <p>
      Действующая редакция всегда находится на странице по адресу:{' '}
      <a href="https://kespa.ru/privacy-policy">
        https://kespa.ru/privacy-policy
      </a>
      .
    </p>

    <p>
      К настоящему Соглашению и отношениям между пользователем и Сайтом,
      возникающим в связи с применением Соглашения подлежит применению
      материальное и процессуальное право Российской Федерации.
    </p>
  </div>
);

export default wrapInLayout(wrapInSection(PrivacyPolicy));
